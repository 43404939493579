
class AppUtils {
  getAPIUrl () {
    return 'https://khalil-web-back.vercel.app/api'
    // if (this.isDevelopment()) {
    //   return 'http://localhost:9021'
    // } else {
    //   return 'https://khalil-web-back.vercel.app/api'
    // }
  }

  isDevelopment () {
    return typeof 'window' !== 'undefined' && window
      .location.href.indexOf('http://localhost') === 0;
  }
}

const appUtils = new AppUtils();

export { appUtils }